import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';

import { resendEmailConfirmation } from '../api';

import { MutationConfig } from '@/lib/react-query';

type UseResendEmail = {
  onClose?: () => void;
  config?: MutationConfig<typeof resendEmailConfirmation>;
};

export const useResendEmail = ({ config, onClose }: UseResendEmail) => {
  const toast = useToast();

  return useMutation({
    useErrorBoundary: false,
    mutationFn: resendEmailConfirmation,
    onSuccess: () => {
      toast({
        title: 'Email reenviado com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      if (onClose) onClose();
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro, tente novamente mais tarde.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
    ...config,
  });
};
