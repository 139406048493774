export const ptBRMessages = {
  // locale switcher
  localeswticher_label: 'Idioma',

  // sidebar
  sidebar_home_title: 'Início',
  sidebar_playlists_title: 'Playlists',
  sidebar_discover_title: 'Descubra',
  sidebar_discover_route: '/descubra',
  sidebar_profile_title: 'Perfil',
  sidebar_profile_route: '/perfil',
  sidebar_users_title: 'Usuários',
  sidebar_users_route: '/usuarios',
  sidebar_login_title: 'Entrar',
  sidebar_logout_title: 'Sair',

  // auth
  auth_loginlayout_title: 'Entrar na plataforma',
  auth_registerlayout_title: 'Criar uma conta',

  auth_loginform_email: 'Email',
  auth_loginform_password: 'Senha',
  auth_loginform_button: 'Entrar',
  auth_loginform_button_facebook: 'Entre com o Facebook',
  auth_loginform_button_google: 'Entre com o Google',
  auth_loginform_forgotpass: 'Esqueceu sua senha?',
  auth_loginform_register: 'Criar uma conta',
  auth_loginform_social: 'Faça login por',
  auth_forgot_password: 'Esqueceu sua senha?',
  auth_dont_have_account: 'Não possui uma conta?',
  auth_dont_have_account_create: 'Criar conta',
  auth_have_account: 'Possui uma conta?',
  auth_have_account_login: 'Entrar',

  auth_login_successfeedback: 'Logado com sucesso.',

  auth_registerform_name: 'Nome completo',
  auth_registerform_email: 'Email',
  auth_registerform_phone: 'Telefone',
  auth_registerform_password: 'Senha',
  auth_registerform_confirmpassword: 'Confirmar senha',
  auth_registerform_button: 'Criar conta',
  auth_registerform_buttonback: 'Voltar para o login',

  auth_register_successfeedback: 'Conta criada com sucesso.',

  // home
  home_highlightedevents_title: 'Destaques',
  home_nextevents_title: 'Próximos eventos',
  home_morerelevants_title: 'Mais relevantes',
  home_suggestedplaylists_title: 'Playlists sugeridas',

  // discover
  discover_eventstabs_title: 'Eventos',
  discover_eventstabs_happening_now_events: 'Acontecendo agora',
  discover_eventstabs_allevents: 'Todos',
  discover_eventstabs_new_events: 'Novos eventos',
  discover_eventstabs_old_events: 'Eventos antigos',
  discover_eventstabs_favorites: 'Eventos favoritados',
  discover_eventstabs_attend: 'Eventos que participo',
  discover_eventstabs_myevents: 'Meus eventos',
  discover_stagestabs_title: 'Stages',
  discover_stagestabs_allstages: 'Todos',
  discover_stagestabs_morerelevants: 'Mais relevantes',
  discover_stagestabs_subscribed: 'Inscrito',
  discover_stagestabs_owner: 'Proprietário',
  discover_eventstabs_not_found: 'Nenhum evento foi encontrado',
  discover_stagestabs_not_found: 'Nenhum stage foi encontrado',
  discover_stagetabs_manage: 'Gerenciar',
  discover_stagetabs_subscribe: 'Inscreva-se',
  discover_stagetabs_subscribed: 'Inscrito',

  // event
  event_route: '/previa-evento',

  events_eventcard_live: 'ao vivo',
  events_eventcard_timeago: 'há 30 minutos',
  events_eventcard_whenlabel: 'Quando',
  events_eventcard_stagelabel: 'Stage',
  events_eventcard_pricelabel: 'Preço',
  events_eventcard_pricefree: 'Grátis',
  events_eventcard_participate: 'Participar',
  events_eventbanner_dayslabel: 'dias',
  events_eventcard_sharelabel: 'Compartilhar',
  events_eventcard_savelabel: 'Salvar',
  events_eventbanner_daysagolabel: 'dias atrás',
  events_futureevent_info: 'Informações do evento',
  events_futureevent_trailer: 'Trailer do evento',
  events_futureevent_schedule: 'Programação',
  events_futureevent_schedule_info_message: 'A programação ainda não foi adicionada',
  events_futureevent_suggestedtitle: 'Eventos que você pode gostar',
  events_futureevent_seedetails: 'Ver detalhes',
  events_futureevent_watchevent: 'Assistir evento',
  events_createevent_title: 'Criar evento',
  events_createstage_title: 'Criar stage',
  events_happening_now_product_title: 'Shopping',
  events_happening_now_edit: 'Editar',
  events_happening_now_reports: 'Ver relatórios',
  events_happening_now_like: 'Curtir',
  events_happening_now_share: 'Compartilhar',
  events_happening_now_send_chat: 'Escreva sua mensagem',
  events_happening_now_send_chat_button: 'Enviar',
  events_createevent_form_title_detail: 'Detalhamento do evento',
  events_createevent_form_name: 'Nome',
  events_createevent_form_startdate: 'Data início',
  events_createevent_form_enddate: 'Data término',
  events_createevent_form_timezone: 'Fuso horário',
  events_createevent_form_synopsis: 'Sinopse',
  events_createevent_form_add_profile_picture: 'Adicionar imagem de perfil',
  events_createevent_form_add_wallpaper_picture: 'Adicionar imagem de capa',
  events_createevent_form_add_thumbnail_picture: 'Adicionar thumbnail',
  events_createevent_form_add_trailer: 'Adicionar trailer',
  events_createevent_form_title_event_schedule: 'Programação do evento',
  events_createevent_form_add_event_schedule: 'Adicionar programação',
  events_createevent_form_add_event_schedule_active: 'Ativado',
  events_createevent_form_add_event_schedule_disabled: 'Desativado',
  events_createevent_form_add_event_schedule_add: 'Adicionar',
  events_createevent_form_add_event_schedule_hour: 'Hora',
  events_createevent_form_add_event_schedule_title: 'Título',
  events_createevent_form_title_event_view: 'Visualização do evento',
  events_createevent_form_who_has_access: 'Quem tem acesso',
  events_createevent_form_who_has_access_public: 'Público',
  events_createevent_form_who_has_access_not_listed: 'Não listado',
  events_createevent_form_who_has_access_not_listed_link: 'Link de acesso (clique para copiar)',
  events_createevent_form_title_entrance: 'Entrada',
  events_createevent_form_event_type: 'Tipo de evento',
  events_createevent_form_event_type_free: 'Evento grátis',
  events_createevent_form_event_type_paid: 'Evento pago',
  events_createevent_form_title_video_player: 'Player de vídeo',

  // stage

  stages_stagepage_event_button: 'Evento',
  stages_stagepage_category_button: 'Categorias',
  stages_stagepage_feed_button: 'Feed',
  stages_stagepage_products: 'Seus produtos',
  stages_stagepage_on_air: 'Acontecendo agora',
  stages_stagepage_next_events: 'Próximos eventos',
  stages_stagepage_unavailable: 'Indisponível',
  stages_stagepage_subscribed: 'Inscrito',
  stages_stagepage_subscribe: 'Inscreva-se',
  stages_stagepage_event_not_created: 'Esse stage ainda não criou um evento',

  //profile

  profile_card_plan: 'Plano',
  profile_card_telephone: 'Telefone',
  profile_card_address: 'Endereço',
  profile_card_payment_status: 'Status do pagamento',
  profile_card_payment_method: 'Método do pagamento',
  profile_tab_title: 'Meu perfil',
  profile_tab_event_button: 'Criar evento',
  profile_tab_stage_button: 'Criar stage',
  profile_tab_invitation_button: 'Criar convite',
  profile_stage_card_title: 'Meus stages',
  profile_event_card_title: 'Meus eventos',
  profile_stage_next_events_title: 'Próximos eventos',
  profile_stage_info_message: 'Você não possui nenhum stage',
  profile_event_info_message: 'Você não possui nenhum evento',
  profile_stage_subscribers_title: 'Stages inscritos',
  profile_stage_subscribed_message: 'Você não está inscrito em nenhum stage',
  profile_stage_subscriber: 'inscrito',
  profile_stage_subscribers: 'inscritos',
  profile_stage_purchases: 'Suas compras',
  profile_purchases_table_purchase: 'Compra',
  profile_purchases_table_date: 'Quando',
  profile_purchases_table_price: 'Valor',
};
