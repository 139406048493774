import create from 'zustand';

type PaymentState = {
  event: {
    id: string;
    name: string;
    value: number;
    ticketName: string;
    slug: string;
    type: string;
    quantity?: number;
    coupon?: string;
    eventId?: string;
  };
  create: (event) => void;
};

export const usePayment = create<PaymentState>((set) => ({
  event: {
    id: '',
    name: '',
    value: 0,
    ticketName: '',
    slug: '',
    type: '',
    quantity: 0,
    count: '',
    eventId: '',
  },
  create: (event) =>
    set({
      event: {
        ...event,
      },
    }),
}));
