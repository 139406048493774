import { useToast } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';

import { LoginForm } from '../components/LoginForm';
import { LoginLayout } from '../components/LoginLayout';

import { CustomizedState } from '@/types/global';

export const Login = () => {
  const intl = useIntl();
  const toast = useToast();
  let locationToRedirect: string;
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as CustomizedState;
  const eventPath = localStorage.getItem('event-path');
  const invitePath = localStorage.getItem('invite-path');

  return (
    <LoginLayout>
      <LoginForm
        onSuccess={() => {
          toast({
            title: intl.formatMessage({
              id: 'auth_login_successfeedback',
            }),
            status: 'success',
            duration: 5000,
            isClosable: true,
          });

          if (!state && !eventPath && !invitePath) {
            locationToRedirect = '/';
          } else if (!state && eventPath) {
            locationToRedirect = eventPath;
            localStorage.removeItem('event-path');
          } else if (!state && invitePath) {
            locationToRedirect = invitePath;
            localStorage.removeItem('invite-path');
          } else {
            locationToRedirect = state.from;
            localStorage.removeItem('event-path');
            localStorage.removeItem('invite-path');
          }

          navigate(locationToRedirect, { replace: true });
        }}
        onError={(message: string) => {
          return toast({
            title: message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }}
        redirectValues={{ state, eventPath, invitePath }}
      />
    </LoginLayout>
  );
};
