import { Center } from '@chakra-ui/react';

import { initReactQueryAuth } from './context';
import history from './history';

import { Spinner } from '@/components/common/Spinner';
import {
  loginWithEmailAndPassword,
  loginWithGoogle,
  loginWithFacebook,
  registerWithEmailAndPassword,
  UserResponse,
  LoginCredentials,
  RegisterCredentials,
  refetchUserWithId,
} from '@/modules/auth';
import { User } from '@/types/global';
import { storage, cookies } from '@/utils';

function handleUserResponse(data: UserResponse) {
  const { accessToken, refreshToken, user } = data;

  if (user && accessToken && refreshToken) {
    storage.setUser(user);
    cookies.setAccess(accessToken);
    cookies.setRefresh(refreshToken);
    return user;
  }

  return null;
}

async function loadUser(): Promise<any> {
  const access = cookies.getAccess();
  const refresh = cookies.getRefresh();

  const user: User = storage.getUser();

  if (!user || !access || !refresh) {
    storage.clearUser();
    cookies.clearAccess();
    cookies.clearRefresh();
    return null;
  }

  const nextUser = await refetchUserWithId(user.id);

  if (nextUser) return nextUser;
}

async function loginFn({
  res,
  data,
  isGmail = false,
  isFacebook = false,
  isClapme = false,
}: {
  data: LoginCredentials;
  isGmail?: boolean;
  isFacebook?: boolean;
  isClapme?: boolean;
  res?: any;
}): Promise<any> {
  const nextGoogleData = {
    accessToken: res?.accessToken,
    tokenId: res?.tokenId,
    name: res?.profileObj?.name,
  };

  const nextFacebookData = {
    accessToken: res?.accessToken,
    name: res?.name,
  };

  if (isClapme) {
    const response = await loginWithEmailAndPassword(data);

    if (response) {
      const user = handleUserResponse(response);
      return user;
    }
  }

  if (isGmail) {
    const response = await loginWithGoogle(nextGoogleData);

    if (response) {
      const user = handleUserResponse(response);
      return user;
    }
  }

  if (isFacebook) {
    const response = await loginWithFacebook(nextFacebookData);

    if (response) {
      const user = handleUserResponse(response);
      return user;
    }
  }

  return null;
}

async function registerFn({ data, inviteToken }: RegisterCredentials): Promise<any> {
  const { message, user } = await registerWithEmailAndPassword({ data, inviteToken });
  return { user, message };
}

async function logoutFn() {
  storage.clearUser();
  cookies.clearAccess();
  cookies.clearRefresh();
  history.push('/login');
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <Center w="100vw" h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  User | null,
  unknown,
  {
    data: LoginCredentials;
    isGmail?: boolean;
    isFacebook?: boolean;
    isClapme?: boolean;
    res?: any;
  },
  RegisterCredentials
>(authConfig);
