import {
  LoginCredentials,
  LoginGoogleCredentials,
  LoginFacebookCredentials,
  UserResponse,
  RegisterCredentials,
  ForgotPasswordCredentials,
  RegisterUserResponse,
  ResendEmailCredentials,
  InviteCredentials,
} from './types';

import axios from '@/lib/axios';
import { User } from '@/types/global';

export const loginWithEmailAndPassword = (data: LoginCredentials): Promise<UserResponse> => {
  return axios.unauthorized({}).post('/login', data);
};

export const loginWithGoogle = (data: LoginGoogleCredentials): Promise<UserResponse> => {
  return axios.unauthorized({}).post('/login/gmail', data);
};

export const loginWithFacebook = (data: LoginFacebookCredentials): Promise<UserResponse> => {
  return axios.unauthorized({}).post('/login/facebook', data);
};

export const refetchUserWithId = (id: string): Promise<User> => {
  return axios.authorized({}).get(`/users/${id}`);
};

export const registerWithEmailAndPassword = ({
  data,
  inviteToken,
}: RegisterCredentials): Promise<RegisterUserResponse> => {
  let config = {};

  if (inviteToken) {
    config = {
      'invite-token': inviteToken,
      'content-type': 'multipart/form-data',
    };
  } else {
    config = {
      'content-type': 'multipart/form-data',
    };
  }

  return axios.unauthorized({}).post('/users', data, { headers: config });
};

export const forgotPassword = (data: ForgotPasswordCredentials): Promise<any> => {
  return axios.unauthorized({}).post('forgot-password', data);
};

export const resendEmailConfirmation = (data: ResendEmailCredentials) => {
  return axios.unauthorized({}).post('resend-email-confirmation', data);
};

export const postConfirmPassword = (data: { code: string }) => {
  return axios.unauthorized({}).post('email-verification-confirmation', data);
};

export const InviteRequest = ({ type, token, requestId, inviteTypeRequest }: InviteCredentials) => {
  let newType: string | null = '';

  switch (type) {
    case 'evento':
      newType = 'event';
      break;
    default:
      newType = type;
      break;
  }

  return axios
    .authorized({})
    .patch(`/${newType}-managers/${requestId}/${inviteTypeRequest}-invite`, null, {
      headers: { 'invite-token': token },
    });
};
