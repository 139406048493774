import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { enUS, ptBR } from 'date-fns/locale';
import { FieldProps } from 'formik';
import DatePicker, { registerLocale, ReactDatePickerProps } from 'react-datepicker';
import { AiOutlineCalendar } from 'react-icons/ai';

import { useLocale } from '@/i18n';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

registerLocale('ptBR', ptBR);
registerLocale('enUS', enUS);

type ReactDayPickerInputProps = {
  value?: string;
  onClick?: string;
  onChange?: string;
  placeholder?: string;
};

export interface DatePickerProps extends Omit<ReactDatePickerProps, 'onChange'>, FieldProps {
  inputProps?: any;
  dayPickerProps?: any;
  placeholder?: string;
  timezone?: string;
}

const CustomInput = ({ value, onClick, onChange, ...rest }: ReactDayPickerInputProps) => (
  <InputGroup>
    <InputLeftElement>
      <AiOutlineCalendar size="20" />
    </InputLeftElement>
    <Input value={value} onClick={onClick as any} readOnly onChange={onChange as any} {...rest} />
  </InputGroup>
);

export const DateField = ({ field, form, ...rest }: DatePickerProps) => {
  const { name, value } = field;
  const { setFieldValue } = form;
  const { currentLocale } = useLocale();

  const dateFormat = currentLocale === 'pt-BR' ? 'dd/MM/yyyy H:mm aa' : 'MM/dd/yyyy h:mm aa';

  const $setFieldValue = (value) => setFieldValue(name, value);

  return (
    <DatePicker
      {...rest}
      showTimeSelect
      timeIntervals={15}
      dateFormat={dateFormat}
      customInput={<CustomInput />}
      onChange={(date: Date) => $setFieldValue(date)}
      locale={currentLocale === 'pt-BR' ? 'ptBR' : 'enUS'}
      selected={value}
      timeCaption={currentLocale === 'pt-BR' ? 'Horário' : 'Time'}
      placeholderText={currentLocale === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
    />
  );
};
