import {
  Button,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Stack,
} from '@chakra-ui/react';
import { FocusableElement } from '@chakra-ui/utils';
import { useRef } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { InviteRequest } from '../../api';

import { Spinner } from '@/components/common/Spinner';
import { useQueryParams } from '@/hooks/useQueryParams';

export const RefuseInviteDialog = ({ isOpen, onClose }) => {
  const toast = useToast();
  const query = useQueryParams();
  const navigate = useNavigate();
  const cancelRef = useRef<FocusableElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const type = query.get('type');
  const token = query.get('token');
  const eventId = query.get('eventId');
  const stageId = query.get('stageId');
  const requestId = stageId || eventId;

  const { mutateAsync, isLoading } = useMutation({
    useErrorBoundary: false,
    mutationFn: InviteRequest,
    onError: (error: any) => {
      toast({
        title: error.response.data.message || 'Ocorreu um erro ao tentar recusar o seu convite.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
    onSuccess: () => {
      toast({
        title: 'Convite recusado com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/', { replace: true });
    },
  });

  return (
    <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent bg="#9d97c4db" color="white">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Recusar convite
          </AlertDialogHeader>

          <AlertDialogBody>Tem certeza? Você não pode desfazer esta ação depois.</AlertDialogBody>

          <AlertDialogFooter>
            {!isLoading ? (
              <>
                <Button variant="@primary" ref={buttonRef} onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  ml={3}
                  variant="@danger"
                  onClick={() =>
                    mutateAsync({
                      type,
                      token,
                      requestId,
                      decision: false,
                      inviteTypeRequest: 'refuse',
                    })
                  }
                >
                  Continuar
                </Button>
              </>
            ) : (
              <Stack alignItems="center" justifyContent="center" w="100%" h="100%">
                <Spinner size="xl" />
              </Stack>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
