import { InputGroup, InputRightElement, Spinner } from '@chakra-ui/react';
import { FieldInputProps, FieldMetaProps, FormikProps } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

type PhoneInputProps = {
  size?: string;
  field: FieldInputProps<any>;
  meta: FieldMetaProps<any>;
  form: FormikProps<any>;
  small?: string;
};

export const PhoneNumber = ({ size = 'sm', small = '10%', field, meta, form }: PhoneInputProps) => {
  const { name, value } = field;
  const { touched } = meta;
  const { isSubmitting, isValidating, setFieldValue } = form;

  return (
    <InputGroup size={size}>
      <PhoneInput
        value={value}
        onChange={(valueNumber) => setFieldValue(name, '+' + valueNumber)}
        inputStyle={{
          width: '100%',
          height: '2.6rem',
          background: 'rgba(255, 255, 255, 0.36)',
          borderColor: 'rgba(255, 255, 255, 0.64)',
          color: 'white',
          fontSize: '1rem',
        }}
        dropdownStyle={{ color: 'black' }}
        buttonStyle={{
          background: 'rgba(255, 255, 255, 0.36)',
          borderColor: 'none',
          border: 'none',
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          width: small,
          textAlign: 'left',
        }}
        country="br"
      />
      {(touched || isSubmitting) && isValidating && (
        <InputRightElement>
          <Spinner size="sm" flex="none" />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
