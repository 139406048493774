import {
  Box,
  Center,
  Divider,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RiMailCheckFill } from 'react-icons/ri';
import { useNavigate } from 'react-router';

import { useResendEmail } from '../../hooks';

import { Spinner } from '@/components/common/Spinner';

export const EmailSendedModal = ({ isOpen, onClose, email }) => {
  const navigate = useNavigate();

  const resendEmailMutation = useResendEmail({});

  return (
    <Modal
      size="xl"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        navigate('/login');
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="whiteAlpha.50" backdropFilter="blur(5px) hue-rotate(15deg)" />
      <ModalContent
        rounded="md"
        color="black"
        bg="white"
        h={['', '500px', '400px', '400px', '400px']}
        width={{ base: 'calc(100% - 50px)' }}
      >
        <ModalCloseButton color="white" />
        <Center w="100%" h="100%">
          <VStack w="100%" h="100%">
            <Stack
              flex="1"
              alignItems="center"
              justifyContent="center"
              w="100%"
              h="100%"
              bg="green.500"
              rounded="md"
            >
              <Box p="4" border="6px solid white" borderRadius="50%">
                <RiMailCheckFill size={70} color="#fff" />
              </Box>
            </Stack>
            <Stack
              flex="1.1"
              alignItems="center"
              px={['4', '6', '3', '3', '4']}
              justifyContent="center"
              pb="5"
              w="100%"
              h="100%"
            >
              <Heading mb="0" pb="4" textAlign="center" fontSize="2xl" fontWeight="normal">
                Confirmação de e-mail
              </Heading>
              <Text fontSize={['sm', 'md', 'md', 'md', 'md']} textAlign="center">
                Enviamos um e-mail de validação para confirmar se o endereço{' '}
                {email && (
                  <span style={{ marginRight: '5px' }}>
                    <strong>{email}</strong>
                  </span>
                )}
                é valido. Após receber o e-mail, siga o link fornecido para concluir seu cadastro.
              </Text>

              <Box w="100%" px="6" pt="2" pb="3">
                <Divider />
              </Box>
              <Stack
                direction={['column', 'column', 'row', 'row', 'row']}
                alignItems="center"
                p={0}
              >
                {resendEmailMutation.isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <Text fontSize={['sm', 'md', 'md', 'md', 'md']} textAlign="center">
                      Se você não recebeu nenhum email
                    </Text>
                    <Text
                      color="blue.500"
                      fontWeight="bold"
                      cursor="pointer"
                      _hover={{ opacity: 0.8 }}
                      onClick={async () => await resendEmailMutation.mutateAsync({ email: email })}
                    >
                      Reenviar email
                    </Text>
                  </>
                )}
              </Stack>
            </Stack>
          </VStack>
        </Center>
      </ModalContent>
    </Modal>
  );
};
