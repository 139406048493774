import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Button,
  Heading,
  HStack,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useMediaQuery,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Navigate, useLocation, useNavigate } from 'react-router';

import { ConfirmationLayout } from '../components/ConfirmationLayout';
import { useResendEmail } from '../hooks';
import { useConfirmPassword } from '../hooks/useConfirmPassword';

import { FieldWrapper, Form } from '@/components/Form';

type FormProps = {
  verificationCode: string;
};

export const CodeEmailConfirmation = () => {
  const resendEmailMutation = useResendEmail({});
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const credentials = location.state as {
    email: string;
    password: string;
    redirectValues: {
      state: { from: string };
      eventPath: string | null;
      invitePath: string | null;
    };
  };
  const [isSmallScreen] = useMediaQuery('(max-width: 860px)');

  const confirmEmail = useConfirmPassword({
    credentials: {
      email: credentials?.email && credentials.email,
      password: credentials?.password && credentials.password,
      redirectValues: credentials?.redirectValues && credentials.redirectValues,
    },
  });

  const handleSubmit = (values: FormProps) => {
    try {
      confirmEmail.mutate({ code: values.verificationCode });
    } catch (err: any) {
      toast({
        description: err?.response?.data?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!credentials) {
    return <Navigate to="/login" />;
  }

  return (
    <ConfirmationLayout>
      <Form<FormProps>
        initialValues={{
          verificationCode: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <>
            <ArrowBackIcon
              display="absolute"
              cursor="pointer"
              right={0}
              width="6"
              height="6"
              onClick={() => navigate('/login')}
            />
            <Stack w="100%" px="4" spacing={10} align="center">
              <VStack spacing={4} textAlign="center">
                <HStack w="100%">
                  <Heading
                    w="100%"
                    mb={0}
                    textAlign="center"
                    letterSpacing="1.50px"
                    fontSize={isSmallScreen ? 'lg' : 'xl'}
                  >
                    Confirme seu e-mail
                  </Heading>
                </HStack>

                <Text fontSize="sm" fontWeight="thin" color="whiteAlpha.700">
                  Preencha com o código que enviamos para o seu e-mail
                </Text>
              </VStack>

              <VStack spacing={0}>
                <FieldWrapper
                  required
                  name="verificationCode"
                  as={(props) => (
                    <HStack spacing={2} justify="center">
                      <PinInput
                        type="alphanumeric"
                        value={props.field.value}
                        onChange={(value) => setFieldValue(props.field.name, value)}
                        {...props}
                      >
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    </HStack>
                  )}
                />

                <Stack
                  fontSize="sm"
                  flexDirection={isSmallScreen ? 'column' : 'row'}
                  textAlign="center"
                  alignItems="center"
                >
                  <Text
                    fontWeight="thin"
                    color="whiteAlpha.700"
                    _hover={{ opacity: 0.8 }}
                    mr={isSmallScreen ? 0 : 2}
                    mb={isSmallScreen ? 2 : 0}
                  >
                    Não recebeu o código?
                  </Text>
                  <Text
                    color="white"
                    fontWeight="bold"
                    cursor="pointer"
                    style={{ marginTop: '0px' }}
                    onClick={async () =>
                      await resendEmailMutation.mutateAsync({ email: credentials.email })
                    }
                  >
                    Clique aqui para reenviar.
                  </Text>
                </Stack>

                <Stack px="4" pt={5} alignItems="center" justifyContent="center">
                  <Button
                    color="white"
                    type="submit"
                    variant="@primary"
                    _hover={{ color: 'gray.300' }}
                  >
                    Confirmar código
                  </Button>
                </Stack>
              </VStack>
            </Stack>
          </>
        )}
      </Form>
    </ConfirmationLayout>
  );
};
