import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { postConfirmPassword } from '../api';
import { LoginCredentialsRedirect } from '../api/types';

import { useAuth } from '@/lib/authentication';
import { MutationConfig, queryClient } from '@/lib/react-query';

type UseConfirmPassword = {
  credentials: LoginCredentialsRedirect;
  onClose?: () => void;
  config?: MutationConfig<typeof postConfirmPassword>;
};

export const useConfirmPassword = ({ config, onClose, credentials }: UseConfirmPassword) => {
  const toast = useToast();
  const { login } = useAuth();
  const navigate = useNavigate();

  return useMutation({
    useErrorBoundary: false,
    mutationFn: postConfirmPassword,
    onSuccess: async () => {
      let locationToRedirect: string;
      const state = credentials.redirectValues.state;
      const eventPath = credentials.redirectValues.eventPath;
      const invitePath = credentials.redirectValues.invitePath;

      const response = await login({
        data: { email: credentials.email, password: credentials.password },
        isClapme: true,
      });

      queryClient.getQueryData('auth-user');

      if (!response) {
        toast({
          title: 'Ocorreu um erro ao tentar fazer login.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        if (!state && !eventPath && !invitePath) {
          locationToRedirect = '/';
        } else if (!state && eventPath) {
          locationToRedirect = eventPath;
          localStorage.removeItem('event-path');
        } else if (!state && invitePath) {
          locationToRedirect = invitePath;
          localStorage.removeItem('invite-path');
        } else {
          locationToRedirect = state.from;
          localStorage.removeItem('event-path');
          localStorage.removeItem('invite-path');
        }
        navigate(locationToRedirect, { replace: true });
      }

      if (onClose) onClose();

      toast({
        title: 'Email confirmado com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: 'Código de verificação inválido.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
    ...config,
  });
};
