import { Helmet } from 'react-helmet';

import clientURL from './clientURL.json';

type MetaTagsProps = {
  title?: string;
  description?: string;
  image?: string;
  imageAlt?: string;
};

const MetaTags = ({ title, description, image, imageAlt }: MetaTagsProps) => {
  const imageOrFallback = image
    ? image
    : 'https://149361159.v2.pressablecdn.com/wp-content/uploads/2021/01/placeholder.png';

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:url"
        content={clientURL.beta + window.location.pathname + window.location.search}
      />
      <meta property="og:image" content={imageOrFallback} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content={imageAlt} />
      <meta property="og:type" content="website" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="ClapMe" />
      <meta property="og:twitter:card" content="@clapme" />
    </Helmet>
  );
};

export default MetaTags;
