import {
  Button,
  Container,
  Heading,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router';

import { InviteRequest } from '../api';
import { RefuseInviteDialog } from '../components/RefuseInviteDialog';

import { Logo } from '@/components/common/Logo';
import { Spinner } from '@/components/common/Spinner';
import { Head } from '@/components/Head';
import * as LC from '@/components/LC';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useAuth } from '@/lib/authentication';

export const ConfirmInvite = () => {
  const toast = useToast();
  const query = useQueryParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const type = query.get('type');
  const name = query.get('name');
  const token = query.get('token');
  const eventId = query.get('eventId');
  const stageId = query.get('stageId');

  const requestId = stageId || eventId;

  const nameFormatted = name?.replaceAll('-', ' ');

  const { mutateAsync, isLoading } = useMutation({
    useErrorBoundary: false,
    mutationFn: InviteRequest,
    onError: (error: any) => {
      toast({
        title: error.response.data.message || 'Ocorreu um erro ao tentar confirmar o seu convite.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
    onSuccess: () => {
      toast({
        title: 'Convite confirmado com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/', { replace: true });
    },
  });

  useEffect(() => {
    if (!user) {
      toast({
        title: 'Faça login para aceitar o convite!',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });

      const isStageOrEvent = stageId ? 'stageId' : 'eventId';

      const newPathName = `${pathname}?token=${token}&${isStageOrEvent}=${requestId}&type=${type}&name=${name}`;

      localStorage.setItem('invite-path', newPathName);
      navigate('/login');
    }
    if ((!eventId && !stageId) || !type || !name || !token) navigate('/', { replace: true });
  }, [eventId, name, navigate, pathname, requestId, stageId, toast, token, type, user]);

  return (
    <>
      <Head title="Confirm Invite" />
      <RefuseInviteDialog isOpen={isOpen} onClose={onClose} />
      {!user ? (
        <Spinner />
      ) : (
        <Container minH="100%" maxW="container.sm">
          <LC.Vertical minH="100%" alignItems="center">
            <LC.Vertical flex={1} pt="10" pb="16">
              <Logo
                cursor="pointer"
                onClick={() => navigate('/login')}
                maxW={['300px', '350px', 'sm']}
              />
            </LC.Vertical>
            <Stack minH="100%">
              <Stack
                color="white"
                flex={1}
                bg="#62589ddc"
                boxShadow="md"
                p="6"
                minH="250px"
                borderRadius={10}
                alignItems="center"
                justifyContent="center"
              >
                <VStack pb="2">
                  <Heading
                    w="100%"
                    mt="2"
                    mb="4"
                    fontSize="1xl"
                    textAlign="center"
                    fontWeight="light"
                  >
                    Voce foi convidado para ser moderador do {type}{' '}
                    <Text
                      mt="6px"
                      fontSize="2xl"
                      fontWeight="black"
                      letterSpacing="1.30px"
                      textTransform="capitalize"
                    >
                      {nameFormatted}
                    </Text>{' '}
                  </Heading>
                  <Stack justifyContent="center" alignItems="center" spacing={6}>
                    <Stack direction="row">
                      {isLoading ? (
                        <Stack alignItems="center" justifyContent="center" w="100%" h="100%">
                          <Spinner size="xl" />
                        </Stack>
                      ) : (
                        <>
                          <Button variant="@danger" onClick={onOpen}>
                            Recusar
                          </Button>
                          <Button
                            variant="@primary"
                            onClick={() =>
                              mutateAsync({
                                type,
                                token,
                                requestId,
                                decision: true,
                                inviteTypeRequest: 'confirm',
                              })
                            }
                          >
                            Aceitar
                          </Button>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </VStack>
              </Stack>
            </Stack>
          </LC.Vertical>
        </Container>
      )}
    </>
  );
};
