export const styles = {
  menu: (styles) => ({
    ...styles,
    background: '#3f3f46',
    borderColor: '#ef4444',
    border: 'none',
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    whiteSpace: 'nowrap',
  }),
  menuList: (styles) => ({
    ...styles,
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      height: '4px',
      background: 'darkgrey',
      borderRadius: '24px',
    },
    '::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: '3px',
      backgroundColor: `#f4f4f5`,
    },
    borderRadius: '3px',
    paddingTop: 0,
    paddingBottom: 0,
  }),
  control: (styles, { selectProps }) => {
    const hasError = selectProps.meta && selectProps.meta.error;
    return {
      ...styles,
      backgroundColor: 'RGBA(255, 255, 255, 0.36)',
      borderRadius: '6px',
      border: `2px solid ${hasError && '#ef4444'}`,
      color: '#fff',
      '&:hover': {
        border: `2px solid ${hasError && '#ef4444'}`,
      },
      padding: '1px 0 1px 0',
    };
  },
  input: (styles) => ({
    ...styles,
    color: '#fff',
    placeholderColor: '#fff',
    width: '100px',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#fff',
    textAlign: 'left',
    padding: '1px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (styles) => ({
    ...styles,
    textTransform: 'capitalize',
    color: '#fff',
  }),
  option: (styles, state) => {
    return {
      ...styles,
      textTransform: 'capitalize',
      '&:hover': {
        background: '#3b82f6',
      },
      '&:active': {
        background: '#3b82f6',
      },
      cursor: state.isDisabled && 'not-allowed',
      opacity: state.isDisabled && '70%',
      background: state.isDisabled
        ? 'none'
        : state.isSelected
        ? '#3b82f6'
        : state.isFocused
        ? '#3b82f6'
        : undefined,
    };
  },
  multiValue: (styles) => ({ ...styles, textTransform: 'capitalize' }),
};
