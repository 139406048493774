import { useToast } from '@chakra-ui/react';
import { useLocation } from 'react-router';

import { RegisterForm } from '../components/RegisterForm';
import { RegisterLayout } from '../components/RegisterLayout';

export const Register = () => {
  const toast = useToast();
  const location = useLocation();
  const state = location.state as {
    state: { from: string };
    eventPath: string | null;
    invitePath: string | null;
  };

  return (
    <RegisterLayout>
      <RegisterForm
        onError={(message) => {
          return toast({
            title: message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }}
        redirectValues={state}
      />
    </RegisterLayout>
  );
};
