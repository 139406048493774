import {
  Box,
  Center,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { IoInformationOutline } from 'react-icons/io5';

type InformationModalProps = {
  isOpen: boolean;
  name: string | null;
  onClose: () => void;
};

export const InformationModal = ({ name, isOpen, onClose }: InformationModalProps) => {
  const nameFormatted = name?.replaceAll('-', ' ');

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  return (
    <Modal size="xl" isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose} isCentered>
      <ModalOverlay bg="whiteAlpha.50" backdropFilter="blur(5px) hue-rotate(15deg)" />
      <ModalContent
        rounded="md"
        color="black"
        bg="white"
        h="350px"
        width={{ base: 'calc(100% - 50px)' }}
      >
        <ModalCloseButton color="white" />
        <Center w="100%" h="100%">
          <VStack w="100%" h="100%">
            <Stack
              flex="1"
              alignItems="center"
              justifyContent="center"
              w="100%"
              h="100%"
              bg="blue.500"
              rounded="md"
            >
              <Box p="4" border="6px solid white" borderRadius="50%">
                <IoInformationOutline size={70} color="#fff" />
              </Box>
            </Stack>
            <Stack
              flex="1"
              alignItems="center"
              justifyContent="center"
              px={['4', '6', '2', '2', '2']}
              pb="5"
              w="100%"
              h="100%"
            >
              <Heading
                mb="0"
                pb="4"
                textAlign="center"
                fontSize={['md', 'md', '2xl', '3xl', '3xl']}
                fontWeight="normal"
              >
                Convite
              </Heading>
              <Text fontSize={['sm', 'sm', 'md', 'md', 'md']} textAlign="center">
                Você foi convidado para participar do <strong>{nameFormatted}</strong>, realize seu
                cadastro antes e logo após entre em sua conta e aceite o convite.
              </Text>
            </Stack>
          </VStack>
        </Center>
      </ModalContent>
    </Modal>
  );
};
