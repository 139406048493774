import { Box, Container, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import bgDetails1 from '@/assets/bg-details-1.png';
import { Head } from '@/components/Head';
import * as LC from '@/components/LC';

type LoginLayoutProps = {
  children: ReactNode;
};

export const ConfirmationLayout = ({ children }: LoginLayoutProps) => {
  const intl = useIntl();

  return (
    <>
      <Head
        title={intl.formatMessage({
          id: 'auth_loginlayout_title',
        })}
      />
      <Box
        bgImage={bgDetails1}
        bgSize="cover"
        bgPosition="top"
        minH="100vh"
        _before={{
          content: '""',
          w: '100%',
          h: '100%',
          pos: 'fixed',
          right: '0',
          top: '0',
          bg: 'brandmatte',
          bgAttachment: 'fixed',
          zIndex: '-1',
        }}
        py={{ base: 7, md: 0 }}
        px={[0, 0, 5, 5]}
      >
        <Container maxW="container.sm">
          <Flex
            minH="100vh"
            alignItems="center"
            direction={{ base: 'column', md: 'row' }}
            justify="center"
          >
            <LC.Vertical
              color="white"
              bg="#62589ddc"
              p={2}
              py={10}
              borderRadius={26}
              justifyContent="center"
              alignItems="center"
              w="100%"
            >
              <Box w={['100%', '90%', '85%']}>{children}</Box>
            </LC.Vertical>
          </Flex>
        </Container>
      </Box>
    </>
  );
};
