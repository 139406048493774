import create from 'zustand';

type ModalState = {
  isOpenInformationModal: boolean;
  onOpenInformationModal: () => void;
  onCloseInformationModal: () => void;
};

export const useInformationModal = create<ModalState>((set) => ({
  isOpenInformationModal: false,
  onOpenInformationModal: () =>
    set(() => ({
      isOpenInformationModal: true,
    })),
  onCloseInformationModal: () =>
    set(() => ({
      isOpenInformationModal: false,
    })),
}));
