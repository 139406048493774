/* eslint-disable no-restricted-imports */
import create from 'zustand';

import { RafflesResponse } from '@/modules/event/api/types';

type ModalState = {
  data: RafflesResponse | null;
  isOpen: boolean;
  toggleOverlay: boolean;
  setData: (newData: RafflesResponse | null) => void;
  onOpen: () => void;
  onClose: () => void;
  setToggleOverlay: (boolean: boolean) => void;
};

export const useOverlayRaffle = create<ModalState>((set) => ({
  data: null,
  isOpen: false,
  toggleOverlay: false,
  onOpen: () =>
    set((state) => ({
      ...state,
      isOpen: true,
    })),
  onClose: () =>
    set((state) => ({
      ...state,
      isOpen: false,
    })),
  setToggleOverlay: (boolean) =>
    set((state) => ({
      ...state,
      toggleOverlay: boolean,
    })),
  setData: (newData) =>
    set((state) => ({
      ...state,
      data: newData,
    })),
}));
