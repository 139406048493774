import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RiMailCloseFill } from 'react-icons/ri';
import { useNavigate } from 'react-router';

import { useResendEmail } from '../../hooks';
import { useConfirmPassword } from '../../hooks/useConfirmPassword';

import { Spinner } from '@/components/common/Spinner';
import { FieldWrapper, Form } from '@/components/Form';
import { CustomizedState } from '@/types/global';

type FormProps = {
  verificationCode: string;
};

type ConfirmEmailErrorModalProps = {
  redirectValues: {
    state: CustomizedState;
    eventPath: string | null;
    invitePath: string | null;
  };
  email: string;
  password: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ConfirmEmailErrorModal = ({
  isOpen,
  onClose,
  email,
  password,
  redirectValues,
}: ConfirmEmailErrorModalProps) => {
  const navigate = useNavigate();
  const confirmEmail = useConfirmPassword({
    onClose,
    credentials: {
      email,
      password,
      redirectValues,
    },
  });
  const resendEmailMutation = useResendEmail({});

  return (
    <Modal
      size="xl"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        navigate('/login');
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="whiteAlpha.50" backdropFilter="blur(5px) hue-rotate(15deg)" />
      <ModalContent rounded="md" color="black" bg="white" width={{ base: 'calc(100% - 50px)' }}>
        <ModalCloseButton color="white" />
        <VStack w="100%" h="100%">
          <Stack
            flex="1"
            alignItems="center"
            justifyContent="center"
            w="100%"
            height="45%"
            bg="red.500"
            borderRadius="5px 5px 0px 0px"
            p={4}
          >
            <Box p="4" border="6px solid white" borderRadius="50%">
              <RiMailCloseFill size="40" color="#fff" />
            </Box>
          </Stack>
          <Stack
            flex="1.1"
            alignItems="center"
            px={['4', '6', '3', '3', '4']}
            justifyContent="center"
            pb="5"
            h="55%"
            w="100%"
          >
            <Heading
              mb="0"
              pb="4"
              textAlign="center"
              fontSize={['xl', 'xl', 'xl', '2xl', '2xl']}
              fontWeight="normal"
            >
              Você precisa confirmar seu email
            </Heading>
            <Text fontSize={['sm', 'sm', 'md', 'md', 'md']} textAlign="center">
              Para fazer login é necessário que seu email seja válido, para isso enviamos um código
              de confirmação para seu email.
            </Text>

            <Text fontSize={['xs', 'sm', 'sm', 'sm', 'sm']} textAlign="center">
              Insira o código abaixo:
            </Text>

            <Form<FormProps>
              initialValues={{
                verificationCode: '',
              }}
              onSubmit={({ verificationCode }) => {
                try {
                  confirmEmail.mutate({ code: verificationCode.toUpperCase() });
                  onClose();
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {({ setFieldValue }) => (
                <Stack w="100%" px="4" spacing={5} mt={5}>
                  <VStack spacing={0}>
                    <FieldWrapper
                      required
                      name="verificationCode"
                      as={(props) => (
                        <HStack spacing={2} justify="center">
                          <PinInput
                            type="alphanumeric"
                            value={props.field.value}
                            onChange={(value) => setFieldValue(props.field.name, value)}
                            {...props}
                          >
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                          </PinInput>
                        </HStack>
                      )}
                    />

                    <Stack px="4" alignItems="center" justifyContent="center">
                      <Button
                        color="white"
                        type="submit"
                        variant="@danger"
                        _hover={{ color: 'gray.300' }}
                      >
                        Confirmar código
                      </Button>
                    </Stack>
                  </VStack>
                </Stack>
              )}
            </Form>

            <Box w="100%" px="6" pt="2" pb="3">
              <Divider />
            </Box>
            <Stack direction={['column', 'column', 'row', 'row', 'row']} alignItems="center" p={0}>
              {resendEmailMutation.isLoading ? (
                <Spinner />
              ) : (
                <>
                  <Text fontSize={['sm', 'sm', 'md', 'md', 'md']} textAlign="center">
                    Se você não recebeu nenhum código por email
                  </Text>
                  <Text
                    color="blue.500"
                    fontWeight="bold"
                    cursor="pointer"
                    _hover={{ opacity: 0.8 }}
                    onClick={async () => await resendEmailMutation.mutateAsync({ email: email })}
                  >
                    Reenviar código
                  </Text>
                </>
              )}
            </Stack>
          </Stack>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
