import {
  Box,
  Button,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { useInformationModal } from '../../store';
import { EmailSendedModal } from '../EmailSendedModal';
import { InformationModal } from '../InformationModal';

import schema from './schema';

import { Link } from '@/components/common/Link';
import { FieldWrapper, Form, TextInput } from '@/components/Form';
import { PhoneNumber } from '@/components/Form/Fields/PhoneNumber';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useAuth } from '@/lib/authentication';

type RegisterFormProps = {
  onError: (message: string) => void;
  redirectValues: {
    state: { from: string };
    eventPath: string | null;
    invitePath: string | null;
  };
};

type FormProps = {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  phone: string;
  language: string;
  role: 'superAdmin' | 'admin' | 'common';
};

export const RegisterForm = ({ onError, redirectValues }: RegisterFormProps) => {
  const intl = useIntl();
  const toast = useToast();
  const query = useQueryParams();
  const navigate = useNavigate();
  const { register, isRegistering } = useAuth();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { isOpenInformationModal, onCloseInformationModal, onOpenInformationModal } =
    useInformationModal();

  const [email, setEmail] = useState();

  const type = query.get('type');
  const eventId = query.get('eventId');
  const stageId = query.get('stageId');
  const stageName = query.get('stageName');
  const eventName = query.get('eventName');
  const inviteToken = query.get('inviteToken');

  const requestId = stageId || eventId;

  useEffect(() => {
    if (inviteToken && (stageName || eventName)) onOpenInformationModal();
  }, [eventName, inviteToken, onOpenInformationModal, stageName]);

  return (
    <Form<FormProps>
      initialValues={{
        name: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: '',
        language: 'pt-br',
        role: 'common',
      }}
      onSubmit={async (values) => {
        try {
          const form: any = new FormData();
          const phoneFormatted = values.phone.replace('(', '').replace(')', '').replace(/\s/g, '');

          const nextValues = {
            ...values,
            phone: phoneFormatted,
          };

          Object.keys(nextValues).forEach((key) => form.set(key, nextValues[key]));

          const response: any = await register({ data: form, inviteToken });
          setEmail(response.user.email);

          if (!response.user.emailVerified) onOpen();

          if (inviteToken) {
            const isStageOrEvent = stageId ? 'stageId' : 'eventId';
            localStorage.setItem(
              'invite-path',
              `/confirm-invite?token=${inviteToken}&${isStageOrEvent}=${requestId}&type=${type}&name=${
                stageName || eventName
              }`,
            );
          }

          navigate('/email-confirmation', {
            replace: inviteToken ? true : false,
            state: { email: values.email, password: values.password, redirectValues },
          });

          toast({
            title: response.message || 'Usuário criado com sucesso!',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        } catch (err: any) {
          onError(err?.response?.data?.message || 'Erro no cadastro');
        }
      }}
      validationSchema={schema}
    >
      {() => (
        <Stack pb="8" spacing={0}>
          <EmailSendedModal email={email} isOpen={isOpen} onClose={onClose} />
          <InformationModal
            name={stageName || eventName}
            isOpen={isOpenInformationModal}
            onClose={onCloseInformationModal}
          />
          <HStack
            w="100%"
            alignItems={['center', 'flex-start']}
            justifyContent="space-between"
            flexDir={['column', 'row']}
            spacing={0}
            mb={[4, 0]}
          >
            <FieldWrapper
              name="name"
              required
              label={intl.formatMessage({
                id: 'auth_registerform_name',
              })}
              as={(props) => <TextInput {...props} />}
            />
          </HStack>
          <HStack
            w="100%"
            alignItems={['center', 'flex-start']}
            justifyContent="space-between"
            flexDir={['column', 'row']}
            spacing={0}
            mb={[4, 0]}
          >
            <FieldWrapper
              name="email"
              required
              label={intl.formatMessage({
                id: 'auth_registerform_email',
              })}
              as={(props) => <TextInput {...props} />}
            />
          </HStack>
          <HStack
            w="100%"
            alignItems={['center', 'flex-start']}
            justifyContent="space-between"
            flexDir={['column', 'row']}
            spacing={0}
            mb={[4, 0]}
          >
            <FieldWrapper
              name="phone"
              required
              label={intl.formatMessage({
                id: 'auth_registerform_phone',
              })}
              as={(props) => <PhoneNumber {...props} />}
            />
          </HStack>
          <HStack
            w="100%"
            alignItems={['center', 'flex-start']}
            justifyContent="space-between"
            flexDir={['column', 'row']}
            spacing={0}
            mb={[4, 0]}
          >
            <FieldWrapper
              name="password"
              required
              label={intl.formatMessage({
                id: 'auth_registerform_password',
              })}
              as={(props) => <TextInput type="password" {...props} />}
            />
          </HStack>
          <HStack
            w="100%"
            alignItems={['center', 'flex-start']}
            justifyContent="space-between"
            flexDir={['column', 'row']}
            spacing={0}
            mb={[4, 0]}
          >
            <FieldWrapper
              name="confirmPassword"
              required
              label={intl.formatMessage({
                id: 'auth_registerform_confirmpassword',
              })}
              as={(props) => <TextInput type="password" {...props} />}
            />
          </HStack>
          <VStack alignItems="center" justifyContent="space-between" mx="auto">
            <Box w={['80%', '80%', '50%', '50%']}>
              <Button
                isLoading={isRegistering}
                type="submit"
                className="w-full"
                variant="@primaryGradient"
                w="100%"
              >
                {intl.formatMessage({
                  id: 'auth_registerform_button',
                })}
              </Button>
            </Box>
            <Stack alignItems="center" direction={['column', 'column', 'row', 'row']}>
              <Text fontSize="sm">
                {intl.formatMessage({
                  id: 'auth_have_account',
                })}
              </Text>
              <Text
                as={Link}
                _hover={{ textDecoration: 'none', opacity: 0.8 }}
                to="/login"
                fontWeight="bold"
              >
                {intl.formatMessage({
                  id: 'auth_have_account_login',
                })}
              </Text>
            </Stack>
          </VStack>
        </Stack>
      )}
    </Form>
  );
};
